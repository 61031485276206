<template lang="pug">
EatView(:loading="viewLoading")
  template(v-slot:default)
    div.bg-white.border-b.px-4.py-2
      EatSearchBar.elevation-1(v-model="searchInput" icon="$search" ref="inputRef" :placeholder="$t('multilocation.searchBarPlaceholder')")
        template(v-slot:append-content)
          EatIconBtn(icon="$position" :padding="3" :loading="isLoadingGeolocation" @click="sortByDistance")
    div.bg-neutral-a.flex-grow-1(v-scrollable="true")
      div.bg-primary.text-center.px-9.py-3 {{ $t("multilocation.orderFromOurLocations") }}
      div.rounded-xxl.container.bg-white.mt-2.mb-5.pt-8
        div.locations__container
          transition-group(name="fade" tag="div" v-if="filteredLocations.length")
            div.location__card.px-3.pb-2(v-for="location in filteredLocations" :key="location.channel")
              a.text-black(:href="getWebappUrl(location)")
                EatImage.location__image.rounded-lg(:src="location.coverImageUrl" aspectRatio="16/9")
                div.pt-3.pb-6
                  div.text-h6.mb-3 {{ location.location.businessName }}
                  div.mb-1 {{ getAddressToString(location.location) }}
                  div.text-primary {{ getOpeningHours(location.location.openingHours) }}
</template>

<script setup lang="ts">
import { locations as getCompanyLocations } from "@/api/Company";
import EatSearchBar from "@/components/utils/EatSearchBar.vue";
import type { App, AppLocation } from "@/models";
import { setAppTitle } from "@/store/appTitle";
import { onMounted, ref, watch } from "vue";
import type { OpeningHours } from "@/models/AppLocation";
import { DateTime } from "luxon";
import { getDeliveryDistanceMatrix, getGeocodingFromGeolocation } from "@/utils/googleUtils";
import { useGeolocation } from "@/store/delivery/newAddress";
import type { Address } from "@/models/Address";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { Saletype } from "@/models/App";
import { useI18n } from "vue-i18n";
import { setupColors } from "@/styles/setupColors";

interface SortableByDistanceLocation extends App {
  fullAddress: string;
  distance: number;
}

const viewLoading = ref(true);
const locations = ref<SortableByDistanceLocation[]>([]);
const filteredLocations = ref<SortableByDistanceLocation[]>([]);
/* const theme = useTheme(); */
const i18n = useI18n();
const urlBack = ref(window.location.hostname.split(".")[0]);
setAppTitle(i18n.t("multilocation.title").toString());

const { geolocalize } = useGeolocation();
const isLoadingGeolocation = ref(false);

const getAddressToString = (location: AppLocation) => {
  const { city, address, zipCode, province } = location;
  return `${address} ${city}, ${zipCode} ${province}`;
};

type Coords = { lat: number; lng: number };
// Riferimento: https://www.movable-type.co.uk/scripts/latlong.html
// Note in these scripts, I generally use lat/lon for lati­tude/longi­tude in degrees, and φ/λ for lati­tude/longi­tude in radians –
// having found that mixing degrees & radians is often the easiest route to head-scratching bugs...
const getDistance = (coords1: Coords, coords2: Coords) => {
  const R = 6371e3; // metres
  const φ1 = (coords1.lat * Math.PI) / 180; // φ, λ in radians
  const φ2 = (coords2.lat * Math.PI) / 180;
  const Δφ = ((coords2.lat - coords1.lat) * Math.PI) / 180;
  const Δλ = ((coords2.lng - coords1.lng) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres
  return Math.floor(d) / 1000;
};

const sortByDistance = async () => {
  isLoadingGeolocation.value = true;
  locations.value = [];
  const position = ref<{ lat: number; lng: number }>();
  const geolocation = ref<Address>();
  try {
    position.value = await geolocalize(10000);
    for (const location of filteredLocations.value) {
      const fullAddress = getAddressToString(location.location);
      let distance = 0;
      if (location.location.geo?.lat && location.location.geo?.lon) {
        distance = getDistance(position.value, {
          lat: location.location.geo.lat,
          lng: location.location.geo.lon
        });
      } else {
        if (!geolocation.value)
          geolocation.value = await getGeocodingFromGeolocation(position.value);
        distance = await getDeliveryDistanceMatrix(fullAddress, geolocation.value);
      }
      locations.value.push({ ...location, distance, fullAddress });
    }
    locations.value.sort((a, b) => a.distance - b.distance);
    filteredLocations.value = JSON.parse(JSON.stringify(locations.value));
  } catch (e) {
    const error = e as { code: number };
    if (error?.code === 3) {
      setSnackbar(
        i18n.t("deliveryAddress.snackbar.geolocationTimeout").toString(),
        TypeSnackbar.WARNING
      );
    }
  } finally {
    isLoadingGeolocation.value = false;
  }
};

onMounted(async () => {
  const companyLocations = (await getCompanyLocations()) as SortableByDistanceLocation[];
  setupColors(companyLocations[0].primaryColor, companyLocations[0].secondaryColor);
  filteredLocations.value = JSON.parse(JSON.stringify(companyLocations));
  viewLoading.value = false;

  sortByDistance();
});

const getOpeningHours = (openingHours: OpeningHours[] | undefined) => {
  const todaysWeekDay = DateTime.now().weekday % 7;
  const todaysOpeningHoursSlots = openingHours?.find(day => day.weekday === todaysWeekDay)
    ?.slots;
  let openingHoursString = !todaysOpeningHoursSlots?.length
    ? i18n.t("location.closed")
    : i18n.t("location.open") + " ";
  todaysOpeningHoursSlots?.forEach((slot, i) => {
    openingHoursString += `${slot.from} - ${slot.to} ${
      todaysOpeningHoursSlots.length > 1 && i !== todaysOpeningHoursSlots.length - 1
        ? " | "
        : ""
    }`;
  });
  return openingHoursString;
};

const searchInput = ref("");

watch(searchInput, () => {
  if (locations.value.length) {
    filteredLocations.value = JSON.parse(JSON.stringify(locations.value));
    filteredLocations.value = filteredLocations.value.filter(location => {
      return (
        location.fullAddress.toLowerCase().includes(searchInput.value.toLowerCase()) ||
        location.location.businessName.toLowerCase().includes(searchInput.value.toLowerCase())
      );
    });
  }
});

const getWebappUrl = ({ appUrl, services }: App) => {
  if (
    services[Saletype.DELIVERY].active ||
    services[Saletype.TAKEAWAY].active ||
    services[Saletype.RESERVATION].active
  )
    return appUrl;
  if (services[Saletype.TAKEAWAY_ON_SITE].active) return `${appUrl}/takeAwayOnSite`;
  else return `${appUrl}/menu`;
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
