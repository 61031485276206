import { createRouter, createWebHashHistory, type RouteRecordRaw } from "vue-router";
import EatTopBar from "@/layout/partials/EatTopBar.vue";
import LocationSelector from "@/apps/multilocation/views/LocationSelector.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "locationSelector",
    components: {
      topBar: EatTopBar,
      default: LocationSelector
    },
    meta: {
      mainView: true,
      hideNavbar: true,
      topBarBorderBottom: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: "locationSelector" }
  }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;