<template lang="pug">
App
</template>

<script setup lang="ts">
import App from "@/App.vue";
import { useRecaptchaProvider } from 'vue-recaptcha/head';

useRecaptchaProvider()
</script>
